.v-badge {
  border-radius: var(--border-radius);
  padding: 0.25em 0.5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.status-success,
.status-confirmed,
.status-completed,
.status-verified,
.status-kyc,
.status-approved,
.status-enable,
.status-opening {
  background: #c8e6c9;
  color: #256029;
}

.status-error,
.status-declined,
.status-declined_verified,
.status-init,
.status-closed{
  background: #ffcdd2;
  color: #c63737;
}

.status-pending,
.status-pending_kyc,
.status-pending_verified,
.status-disable {
  background: #feedaf;
  color: #8a5340;
}

.status-new {
  background: #b3e5fc;
  color: #23547b;
}

.status-blocked {
  background: #eccfff;
  color: #694382;
}

.status-proposal {
  background: #ffd8b2;
  color: #805b36;
}

body .p-datepicker{
  z-index: 1102 !important;
}

.invest-status-error {
  background: #ffcdd2;
  color: #c63737;
}
.invest-status-success {
  background: #c8e6c9;
  color: #256029;
}
.invest-status-waiting_user_confirm {
  background: #f35757;
  color: #fff;
}
.invest-status-waiting_admin_confirm {
  background: #ff9900;
  color: #fff;
}
.invest-status-waiting_payback {
  background: #ff5800;
  color: #fff;
}
.invest-status-reinvested {
  background: #6b46c1;
  color: #fff;
}
.invest-status-completed {
  background: #969696;
  color: #fff;
}
.text-cancel-invest{
  color: #EF4444;
  font-weight: 600;
}
.btn-end{
  text-align: end;
}